<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__inner">
        <div class="footer__social">
          <h2><span class="sr-only">Connect with me</span></h2>
          <div class="socialicons">
            <span class="socialicon">
              <a href="https://www.linkedin.com/in/rhysmatthewp/" target="_blank" rel="noopener">
                <span class="sr-only">LinkedIn</span>
                <span class="socialicon__icon"><IconLinkedin></IconLinkedin></span>
              </a>
            </span>
            <span class="socialicon socialicon--twitter">
              <a href="https://twitter.com/rhysmatthewp" target="_blank" rel="noopener">
                <span class="sr-only">Twitter</span>
                <span class="socialicon__icon"><IconTwitter></IconTwitter></span>
              </a>
            </span>
            <span class="socialicon">
              <a href="https://github.com/Rhyspacker" target="_blank" rel="noopener">
                <span class="sr-only">Github</span>
                <span class="socialicon__icon"><IconGithub></IconGithub></span>
              </a>
            </span>
            <span class="socialicon">
              <a href="https://codepen.io/rhyspacker/" target="_blank" rel="noopener">
                <span class="sr-only">Codepen</span>
                <span class="socialicon__icon"><IconCodepen></IconCodepen></span>
              </a>
            </span>
          </div>
        </div>
        <p class="mb-0"><small>&copy; Rhys Matthew {{ getCurrentYear }}</small></p>
        <p><small>Built with ❤️ using Vue JS and Prismic CMS</small></p>
      </div>
      <Decor
          v-if="decorCanRender === true"
          :id="'2'"
      ></Decor>
    </div>
  </footer>
</template>

<script>
import IconLinkedin from '../components/icons/IconLinkedin'
import IconTwitter from '../components/icons/IconTwitter'
import IconGithub from '../components/icons/IconGithub'
import IconCodepen from '../components/icons/IconCodepen'

// Decor
import Decor from '../components/decor/Decor'
import debounce from 'lodash/debounce'

export default {
  name: 'AppFooter',
  data: function () {
    return {
      decorCanRender: false
    }
  },
  components: {
    IconLinkedin,
    IconTwitter,
    IconGithub,
    IconCodepen,
    Decor
  },
  mounted () {
    this.decorCanRenderCheck()
    this.decorUpdateOnResize()
  },
  methods: {
    decorCanRenderCheck: function () {
      const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

      // Show decor if page is home and above 1024 (desktop), else hide it
      //
      if (windowWidth < 1024) {
        this.decorCanRender = false
      } else if (windowWidth >= 1024) {
        this.decorCanRender = true
      }
    },
    decorUpdateOnResize: function () {
      window.addEventListener('resize', debounce(this.decorCanRenderCheck, 250, { leading: true, trailing: true }))
    }
  },
  computed: {
    getCurrentYear: function () {
      return new Date().getFullYear()
    }
  },
  watch: {
    $route (to, from) {
      const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

      // Show decor if page is home and above 1024 (desktop), else hide it
      // Also, setTimeout ensures that decor doesn't appear before page transition
      //
      if (windowWidth < 1024) {
        setTimeout(() => {
          this.decorCanRender = false
        }, 750)
      } else if (windowWidth >= 1024) {
        setTimeout(() => {
          this.decorCanRender = true
        }, 750)
      }
    }
  }
}
</script>

<style lang="scss">
// ==============================
// Footer
// ========================================

.footer {
  position: relative;
  margin-top: auto;
  background: transparent;

  &__inner {
    padding: ($spacer-y * 2) 0 ($spacer-y * 0.5) 0;
    text-align: center;

    > :last-child {
      margin-bottom: 0;
    }
  }
}

// ==============================
// Footer Social
// ========================================

.footer__social {
  .socialicon {
    &:not(:last-child) {
      margin-right: $spacer-x/6;
    }
  }
}
</style>
