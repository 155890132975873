// ==============================
// Forms
// ========================================

const forms = {
  config: {
    formFields: '.form__field'
  },

  elements: {
    formFields: null
  },

  init () {
    const self = this

    self.elements.formFields = document.querySelectorAll(self.config.formFields)

    if (self.elements.formFields !== null) {
      for (let i = 0; i < self.elements.formFields.length; i++) {
        const formField = self.elements.formFields[i]

        self.handleFormField(formField)
      }
    }
  },

  handleFormField (formField) {
    const formControl = formField.querySelector('.form__input')

    // Check initially for prefilled data
    //
    if (formControl.value !== '') {
      formControl.classList.add('has--content')
    } else {
      formControl.classList.remove('has--content')
    }

    // Watch for input and check content
    //
    formControl.addEventListener('change', function () {
      if (this.value !== '') {
        formControl.classList.add('has--content')
      } else {
        formControl.classList.remove('has--content')
      }
    })
  }
}

document.addEventListener('pageLoad', () => {
  forms.init()
})
