<template functional>
  <svg :fill="props.fill" class="icon icon--github" :class="props.classes" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 512 512">
    <path d="M179.86 392.88c0 1.86-2.14 3.34-4.82 3.34-3.07.28-5.2-1.2-5.2-3.34 0-1.85 2.13-3.34 4.83-3.34 2.78-.27 5.19 1.21 5.19 3.34zm-28.84-4.17c-.65 1.86 1.2 3.99 3.98 4.54 2.41.93 5.2 0 5.75-1.85.56-1.86-1.2-3.99-3.98-4.82-2.42-.65-5.1.28-5.75 2.13zm40.99-1.58c-2.7.65-4.55 2.41-4.27 4.55.28 1.85 2.7 3.06 5.47 2.41 2.7-.65 4.55-2.41 4.27-4.27-.28-1.76-2.78-2.96-5.47-2.69zm61.02-355.38C124.4 31.75 26 129.4 26 258.04c0 102.85 64.73 190.86 157.2 221.84 11.87 2.13 16.04-5.2 16.04-11.23 0-5.75-.27-37.46-.27-56.94 0 0-64.93 13.91-78.56-27.64 0 0-10.57-26.99-25.78-33.94 0 0-21.24-14.56 1.48-14.28 0 0 23.1 1.85 35.8 23.93 20.31 35.8 54.35 25.5 67.6 19.38 2.14-14.84 8.17-25.13 14.85-31.26-51.84-5.75-104.15-13.26-104.15-102.47 0-25.5 7.05-38.3 21.89-54.63-2.41-6.03-10.3-30.88 2.4-62.97 19.4-6.03 64 25.04 64 25.04a217.9 217.9 0 0 1 58.24-7.88c19.76 0 39.7 2.69 58.24 7.88 0 0 44.61-31.16 64-25.04 12.7 32.18 4.82 56.94 2.4 62.97 14.85 16.42 23.93 29.21 23.93 54.63 0 89.5-54.62 96.63-106.46 102.47 8.53 7.33 15.76 21.24 15.76 43.04 0 31.25-.28 69.92-.28 77.53 0 6.03 4.27 13.35 16.05 11.22C423.12 448.9 486 360.9 486 258.04c0-128.64-104.33-226.3-232.97-226.3zM116.15 351.6c-1.21.93-.93 3.06.64 4.82 1.49 1.49 3.62 2.14 4.83.93 1.2-.93.92-3.06-.65-4.82-1.49-1.48-3.62-2.13-4.82-.93zm-10.02-7.5c-.65 1.2.28 2.68 2.13 3.6 1.49.94 3.34.66 4-.64.64-1.2-.29-2.69-2.14-3.62-1.86-.56-3.34-.28-4 .65zm30.05 33c-1.49 1.21-.93 4 1.2 5.76 2.14 2.13 4.83 2.4 6.03.92 1.2-1.2.65-3.98-1.2-5.75-2.05-2.13-4.83-2.4-6.03-.92zm-10.58-13.63c-1.48.93-1.48 3.34 0 5.47 1.49 2.14 4 3.07 5.2 2.14 1.48-1.2 1.48-3.62 0-5.75-1.3-2.13-3.71-3.06-5.2-1.85z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconGithub',
  props: {
    fill: {
      type: String,
      default: ''
    },
    classes: {
      type: String,
      default: ''
    }
  }
}
</script>
