<template functional>
  <svg :fill="props.fill" class="icon icon--twitter" :class="props.classes" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 512 512">
    <path d="M438.72 162.3c.29 4.1.29 8.18.29 12.27 0 124.63-94.86 268.23-268.24 268.23-53.41 0-103.03-15.47-144.77-42.32 7.59.88 14.89 1.17 22.77 1.17 44.07 0 84.64-14.89 117.04-40.28A94.44 94.44 0 0 1 77.66 296a118.9 118.9 0 0 0 17.8 1.46c8.47 0 16.94-1.17 24.82-3.21a94.3 94.3 0 0 1-75.6-92.53v-1.17a94.96 94.96 0 0 0 42.61 11.97A94.21 94.21 0 0 1 45.26 134a93.57 93.57 0 0 1 12.85-47.58 267.98 267.98 0 0 0 194.39 98.65c-1.46-7-2.34-14.3-2.34-21.6 0-51.95 42.03-94.27 94.28-94.27a94.05 94.05 0 0 1 68.88 29.77 185.59 185.59 0 0 0 59.84-22.77 94.02 94.02 0 0 1-41.45 51.96c18.98-2.04 37.36-7.3 54.3-14.6a202.72 202.72 0 0 1-47.3 48.75z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconTwitter',
  props: {
    fill: {
      type: String,
      default: ''
    },
    classes: {
      type: String,
      default: ''
    }
  }
}
</script>
