import prismicDOM from 'prismic-dom'
import linkResolver from './link-resolver'

const Elements = prismicDOM.RichText.Elements

export default function (type, element, content, children) {
  // Handle custom Markdown-like parsing
  if (type === Elements.paragraph) {
    let content = children[0]

    let contentParsedCodeBlocks = content.replace('`', '<code>').replace('`', '</code>').replace('`', '<code>').replace('`', '</code>').replace('`', '<code>').replace('`', '</code>').replace('`', '<code>').replace('`', '</code>')

    children[0] = contentParsedCodeBlocks

    return `<p>${children.join('')}</p>`
  }

  // Generate links to Prismic Documents as <router-link> components
  if (type === Elements.hyperlink) {
    let result = ''
    const url = prismicDOM.Link.url(element.data, linkResolver)

    if (element.data.link_type === 'Document') {
      result = `<router-link to="${url}">${content}</router-link>`
    } else {
      const target = element.data.target ? `target="'${element.data.target}'" rel="noopener"` : ''
      result = `<a href="${url}" ${target} ${element.data.link_type === 'Media' && target !== '' ? 'target="_blank"' : ''} class="btn"><span class="btn__inner">${content}</span></a>`
    }
    return result
  }

  // If the image is also a link to a Prismic Document, it will return a <router-link> component
  if (type === Elements.image) {
    let result = `<img class='img-fluid' src="${element.url}" alt="${element.alt || ''}" copyright="${element.copyright || ''}">`

    if (element.linkTo) {
      const url = prismicDOM.Link.url(element.linkTo, linkResolver)

      if (element.linkTo.link_type === 'Document') {
        result = `<router-link to="${url}">${result}</router-link>`
      } else {
        const target = element.linkTo.target ? `target="${element.linkTo.target}" rel="noopener"` : ''
        result = `<a href="${url}" ${target}>${result}</a>`
      }
    }
    const wrapperClassList = [element.label || '', 'img-wrapper']
    result = `<div class="${wrapperClassList.join(' ')}">${result}</div>`
    return result
  }

  // Return null to stick with the default behavior for everything else
  return null
}
