<template functional>
  <svg :fill="props.fill" class="icon icon--linkedin" :class="props.classes" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 512 512">
    <path d="M26.62 169.75h102.59V486H26.62zM408.65 173.46c-1.1-.34-2.12-.72-3.27-1.04a64.27 64.27 0 0 0-4.16-.8 91.5 91.5 0 0 0-18.4-1.87c-59.81 0-97.75 43.62-110.24 60.46v-60.46h-102.6V486h102.6V313.5s77.52-108.27 110.23-28.75V486h102.57V272.59c0-47.79-32.66-87.6-76.73-99.13zM76.8 126.63a50.24 50.24 0 0 0 50.18-50.32 50.25 50.25 0 0 0-50.18-50.3 50.25 50.25 0 0 0-50.18 50.3 50.25 50.25 0 0 0 50.18 50.32z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconLinkedin',
  props: {
    fill: {
      type: String,
      default: ''
    },
    classes: {
      type: String,
      default: ''
    }
  }
}
</script>
