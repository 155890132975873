import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./pages/Home.vue')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('./pages/About.vue')
    },
    {
      path: '/projects',
      name: 'projects',
      component: () => import('./pages/Projects.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('./pages/Contact.vue')
    },
    {
      path: '/writing',
      name: 'writing',
      component: () => import('./pages/Writing.vue')
    },
    {
      path: '/writing/:uid',
      name: 'post',
      component: () => import('./pages/Post.vue')
    },
    {
      path: '/404',
      name: 'Page not found',
      component: () => import('./pages/PageNotFound.vue')
    },
    {
      path: '*',
      redirect: '/404'
    }
  ]
})
