// ==============================
// JavaScript Imports
// ========================================

import './js/_main.js'

// ==============================
// Main Imports
// ========================================

import Vue from 'vue'
import vueHeadful from 'vue-headful'
import VueCookie from 'vue-cookie'
import App from './App.vue'
import router from './router'

// ==============================
// Prismic Imports
// ========================================

import PrismicVue from '@prismicio/vue'
import linkResolver from './link-resolver'
import htmlSerializer from './html-serializer'
import accessToken from './prismic-token'

// ==============================
// Components
// ========================================

// Import component scripts
import './components/_components.js'

// Vue Headful Registration
Vue.component('vue-headful', vueHeadful)

// ==============================
// Plugins
// ========================================

// Prismic Plugin Initialisation
Vue.use(PrismicVue, {
  endpoint: 'https://rhysmatthew.prismic.io/api/v2',
  linkResolver: linkResolver,
  htmlSerializer: htmlSerializer,
  apiOptions: { accessToken },
  preview: false
})

// Vue Cookie Initialisation
Vue.use(VueCookie)

// ==============================
// Vue Instance
// ========================================

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
