<template>
  <div v-bind:class="{ 'is--open': menuOpen }" ref="header" style="position: relative">
    <header class="header">
      <div class="container" ref="headerContainer">
        <div class="header__inner">
          <router-link class="header__logo" exact to="/" tabindex="1" v-on:click.native="handleLogoClick($event)">
            <span class="sr-only">Rhys Matthew</span>
            <IconLogo></IconLogo>
          </router-link>
          <button class="header__btn" v-on:click="toggleNavigation($event)" :aria-expanded="menuOpen ? 'true' : 'false'" aria-controls="headerMenu" tabindex="1">
            <span class="sr-only">Menu</span>
            <span class="header__btn__icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>
        </div>
      </div>
    </header>
    <div class="header__menu" id="headerMenu" ref="headerMenu">
      <nav role="navigation">
        <router-link class="header__menu__link" exact to="/" v-on:click.native="toggleNavigation">Home</router-link>
        <router-link class="header__menu__link" exact to="/about" v-on:click.native="toggleNavigation">About</router-link>
        <router-link class="header__menu__link" exact to="/projects" v-on:click.native="toggleNavigation">Projects</router-link>
        <router-link class="header__menu__link" exact to="/writing" v-on:click.native="toggleNavigation">Writing</router-link>
        <router-link class="header__menu__link" exact to="/contact" v-on:click.native="toggleNavigation">Contact</router-link>
      </nav>
    </div>
    <div class="header__transition-overlay" aria-hidden="true" data-frames="25" ref="headerTransitionOverlay">
      <div class="bg-layer"></div>
    </div>
    <Decor v-if="decorCanRender === true" :id="'1'"></Decor>
  </div>
</template>

<script>
// Icons
import IconLogo from '../components/icons/IconLogo'

// Decor
import Decor from '../components/decor/Decor'

// TimelineLite
import { TimelineLite } from 'gsap'

// Throttle and Debounce
import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'

export default {
  name: 'AppHeader',
  components: {
    IconLogo,
    Decor
  },
  data: function () {
    return {
      menuOpen: false,
      menuAnim: null,
      menuLinksAnim: null,
      LastScrollFromTopDistance: 50,
      stickyHeaderOffset: 100,
      transitionOverlayEl: null,
      transitionOverlayBgEl: null,
      transitionOverlaySettings: {
        frameProportion: null,
        frames: null,
        resize: false
      },
      decorCanRender: false
    }
  },
  created () {
    window.addEventListener('scroll', throttle(this.handleScroll, 250, { leading: true, trailing: true }))
  },
  mounted () {
    this.initialiseTimelineLite()
    this.initialisePageTransition()
    this.decorCanRenderCheck()
    this.decorUpdateOnResize()
  },
  methods: {
    initialiseTimelineLite: function () {
      const menuAnim = new TimelineLite({ paused: true, reversed: true })
      const menuLinksAnim = new TimelineLite({ paused: true, reversed: true, immediateRender: false })

      // Check for prefers-reduced-motion setting
      //
      const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)')

      // If prefersReducedMotion is true initialise animation that's instant,
      // or just play standard animation
      //
      if (prefersReducedMotion.matches === true) {
        menuAnim.from('.header__menu', 0.0005, { opacity: 0, y: '-100%' })
          .to('.header__menu', 0.0005, { opacity: 1, y: '-0%' })
        // Make animation accessible to other methods
        this.menuAnim = menuAnim

        menuLinksAnim.staggerFromTo('.header__menu__link', 0.0005, { opacity: 0, y: -20 }, { opacity: 1, y: 0, delay: 0.0005 }, 0.0005)
        // Make animation accessible to other methods
        this.menuLinksAnim = menuLinksAnim
      } else {
        menuAnim.from('.header__menu', 0.3, { opacity: 0, y: '-100%' })
          .to('.header__menu', 0.3, { opacity: 1, y: '-0%' })
        // Make animation accessible to other methods
        this.menuAnim = menuAnim

        menuLinksAnim.staggerFromTo('.header__menu__link', 0.5, { opacity: 0, y: -20 }, { opacity: 1, y: 0, delay: 0.5 }, 0.1)
        // Make animation accessible to other methods
        this.menuLinksAnim = menuLinksAnim
      }
    },
    handleLogoClick: function (event) {
      if (this.menuOpen === true) {
        this.toggleNavigation(event)
      }
    },
    toggleNavigation: function (event) {
      document.body.classList.toggle('no--scroll')

      // If menu is open, we'll close the menu and focus on .page
      // Else we'll open the menu and focus on first link
      //
      if (this.$refs.header.classList.contains('is--open')) {
        // Focus on page
        document.querySelector('.page').focus()

        // Un stick header
        this.$refs.headerContainer.classList.remove('is--visible')

        // Close menu
        this.$refs.header.classList.remove('is--open')
      } else {
        // Focus on navigation
        document.querySelector('.header__menu__link').focus()

        // Stick header in place
        this.$refs.headerContainer.classList.add('is--visible')

        // Open menu
        this.$refs.header.classList.add('is--open')
      }

      // Add and remove .is--clicked after 250ms if header__btn is clicked for tap effect
      if (event.target.classList.contains('header__btn')) {
        event.target.classList.add('is--clicked')

        setTimeout(function () {
          event.target.classList.remove('is--clicked')
        }, 250)
      }

      // Play the animation based on whether menu is open or closed
      if (this.menuAnim.reversed()) {
        this.menuAnim.play()
        this.menuLinksAnim.delay(5).timeScale(1).play()
      } else {
        this.menuAnim.reverse()
        this.menuLinksAnim.delay(0).timeScale(2).reverse()
      }

      this.menuOpen = !this.menuOpen
    },
    handleScroll: function (event) {
      const self = this

      let scrollFromTopDistance = window.pageYOffset || document.documentElement.scrollTop

      // If scrolled down the page we'll fix the header
      // off screen - to be transitioned in based on scroll direction
      //
      if (scrollFromTopDistance > this.stickyHeaderOffset) {
        if (scrollFromTopDistance < self.LastScrollFromTopDistance) {
          self.$refs.headerContainer.classList.remove('not--visible')
        } else {
          self.$refs.headerContainer.classList.add('not--visible')
        }
      } else {
        self.$refs.headerContainer.classList.remove('not--visible')
      }

      this.LastScrollFromTopDistance = scrollFromTopDistance <= this.stickyHeaderOffset ? this.stickyHeaderOffset : scrollFromTopDistance
    },
    initialisePageTransition: function () {
      const self = this
      this.transitionOverlayEl = this.$refs.headerTransitionOverlay
      this.transitionOverlayBgEl = this.transitionOverlayEl.querySelector('.bg-layer')

      const settings = this.transitionOverlaySettings

      settings.frameProportion = 1.78
      settings.frames = parseInt(this.transitionOverlayEl.getAttribute('data-frames'), 10)
      settings.resize = false

      this.setTransitionDimensions()

      window.addEventListener('resize', function () {
        if (!settings.resize) {
          settings.resize = true
          !window.requestAnimationFrame ? setTimeout(self.setTransitionDimensions, 300) : window.requestAnimationFrame(self.setTransitionDimensions)
        }
      })
    },
    playTransition: function () {
      const self = this
      this.transitionOverlayEl.classList.add('is--visible', 'is--transitioning')

      setTimeout(function () {
        self.transitionOverlayEl.classList.remove('is--visible', 'is--transitioning')
      }, 1000)
    },
    setTransitionDimensions: function () {
      const settings = this.transitionOverlaySettings

      let windowWidth = window.innerWidth
      let windowHeight = window.innerHeight
      let layerHeight
      let layerWidth

      if (windowWidth / windowHeight > settings.frameProportion) {
        layerWidth = windowWidth
        layerHeight = layerWidth / settings.frameProportion
      } else {
        layerHeight = windowHeight * 1.2
        layerWidth = layerHeight * settings.frameProportion
      }

      this.transitionOverlayBgEl.style.width = layerWidth * settings.frames + 'px'
      this.transitionOverlayBgEl.style.height = layerHeight + 'px'
      settings.resize = false
    },
    decorCanRenderCheck: function () {
      const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

      // Show decor if page is home and above 1024 (desktop), else hide it
      //
      if (windowWidth < 1024 || this.$route.name !== 'home') {
        this.decorCanRender = false
      } else if (windowWidth >= 1024 && this.$route.name === 'home') {
        this.decorCanRender = true
      }
    },
    decorUpdateOnResize: function () {
      window.addEventListener('resize', debounce(this.decorCanRenderCheck, 250, { leading: true, trailing: true }))
    }
  },
  watch: {
    $route (to, from) {
      this.playTransition()

      const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

      // Show decor if page is home and above 1024 (desktop), else hide it
      // Also, setTimeout ensures that decor doesn't appear before page transition
      //
      if (windowWidth < 1024 || this.$route.name !== 'home') {
        setTimeout(() => {
          this.decorCanRender = false
        }, 750)
      } else if (windowWidth >= 1024 && this.$route.name === 'home') {
        setTimeout(() => {
          this.decorCanRender = true
        }, 750)
      }
    }
  }
}
</script>

<style lang="scss">
// ==============================
// Header
// ========================================

.header {
  // Height defined twice here to ensure when container is sticky that
  // .header retains it's height and prevents page jumps
  //
  height: 60px;

  @include mq($from: mobileLandscape) {
    height: 80px;
  }

  .container {
    position: fixed;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    transition: top 0.225s, background 0.3s 0.3s;
    z-index: $header-zindex-container;

    width: 100%;
    height: 60px;

    @include mq($from: mobileLandscape) {
      height: 80px;
    }

    .is--open & {
      transition: top 0.225s;
      background: none;
    }

    &.not--visible {
      top: -60px;

      @include mq($from: mobileLandscape) {
        top: -80px;
      }
    }

    &.is--visible {
      top: 0 !important;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
}

// ==============================
// Header Logo
// ========================================

.header__logo {
  position: relative;
  z-index: $header-zindex-logo;

  .icon--logo {
    width: 80px;
    height: auto;
    transition: fill 0.3s;
    mix-blend-mode: difference;

    .is--open & {
      .icon--logo__gradient-start, .icon--logo__gradient-end {
        transition: stop-color 0.3s 0.3s;
        stop-color: $color-white !important;

        @media (prefers-reduced-motion) {
          transition: none;
        }
      }
    }

    @include mq($from: mobileLandscape) {
      width: 100px;
    }
  }

  .icon--logo__gradient-start, .icon--logo__gradient-end {
    transition: stop-color 0.3s;

    @media (prefers-reduced-motion) {
      transition: none;
    }
  }

  @include mq($from: desktop) {
    &:hover {
      .icon--logo__gradient-start, .icon--logo__gradient-end {
        stop-color: lighten($color-b-primary, 5%);
      }
    }
  }
}

// ==============================
// Header Button
// ========================================

.header__btn {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba($color-white, 1);
  z-index: $header-zindex-btn;
  transition: all 0.3s;

  @media (prefers-reduced-motion) {
    transition: none;
  }

  .is--open & {
    background: none;
    border: none;
  }

  @include mq($until: mobileLandscape) {
    right: -2px;
  }

  @include mq($until: desktop) {
    @include button-tap-animation($color-grey);
  }

  @include mq($from: desktop) {
    width: 46px;
    height: 46px;

    &:hover {
      background: $color-grey-light;

      .is--open & {
        background: rgba($color-grey, 0.5);
      }
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    pointer-events: none;

    span {
      position: relative;
      display: block;
      width: 20px;
      height: 2px;
      background: $color-black;
      transition: transform 0.3s, top 0.3s, background 0.3s;
      top: 0px;

      @media (prefers-reduced-motion) {
        transition: none;
      }

      &:not(:last-child) {
        margin-bottom: 4px;
      }

      &:nth-of-type(2) {
        transform-origin: center;
        transform: scaleX(1);
      }

      .is--open & {
        transition: transform 0.3s, top 0.3s, background 0.3s 0.3s;
        background: $color-white;

        @media (prefers-reduced-motion) {
          transition: none;
        }

        &:nth-of-type(1) {
          transform: rotate(-45deg);
          top: 6px;
        }
        &:nth-of-type(2) {
          opacity: 0;
          transform: scaleX(0);
        }
        &:nth-of-type(3) {
          transform: rotate(45deg);
          top: -6px;
        }
      }
    }
  }
}

// ==============================
// Header Menu
// ========================================

.header__menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $color-a-primary;
  background: $gradient-diagonal;
  opacity: 0;
  transition: background 0.3s;
  z-index: $header-zindex-menu;

  display: flex;
  align-items: center;
  justify-content: center;

  nav {
    counter-reset: navigation;
    padding-top: $spacer-y*1.5;
    text-align: left;
    max-width: 400px;

    @include mq($from: desktop) {
      max-width: 750px;
    }

    @include mq($from: wide) {
      max-width: 920px;
    }
  }
}

.header__menu__link {
  position: relative;
  left: 0;
  display: block;
  text-decoration: none;
  color: $color-white;
  font-family: $font-family-secondary;
  font-weight: bold;
  font-size: 32px;
  transition: color 0.3s, background 0.3s, left 0.5s;

  @media screen and (min-width: 460px) and (max-height: 480px) {
    font-size: 28px !important;
    width: 50%;
    display: inline-block;
  }

  @media (prefers-reduced-motion) {
    transition: none;
  }

  &:before {
    content: '0' counter(navigation) '.';
    font-family: $font-family-primary;
    font-size: 14px;
    font-weight: bold;
    color: $color-white;
    counter-increment: navigation;
    margin-right: 1rem;
  }

  @media screen and (min-height: 568px) {
    @include mq($from: mobileLandscape) {
      font-size: 48px;
    }
  }

  @include mq($from: 385px) {
    font-size: 38px;
  }

  @include mq($from: tablet) {
    font-size: 60px;
  }

  @include mq($from: desktop) {
    width: 50%;
    display: inline-block;
    font-size: 72px;

    &:before {
      font-size: 18px;
    }

    &:hover {
      left: 4px;
      color: $color-b-primary;
    }
  }

  @include mq($from: wide) {
    font-size: 84px;

    &:before {
      font-size: 20px;
    }
  }
}

// ==============================
// Header Transition
// Courtesy of https://github.com/CodyHouse/animated-transition-effects
// ========================================

.header__transition-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $header-zindex-menu-transition-overlay;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;

  &.is--visible {
    opacity: 1;
    visibility: visible;
  }

  .bg-layer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-2%);

    // Its dimensions will be overwritten using JS to proportionally fit the viewport
    height: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0 0;

    // Sprite composed of 25 frames
    width: 2500%;
  }

  &.is--transitioning {
    .bg-layer {
      animation: cd-sequence 0.8s steps(24) forwards;
      animation-delay: 0.25s;

      @media (prefers-reduced-motion) {
        animation: none;
      }
    }
  }
}

@keyframes cd-sequence {
  0% {
    // translateX(-2%) is used to horizontally center the first frame inside the viewport
    transform: translateY(-50%) translateX(-2%);
  }
  100% {
    // translateX(-98%) (2% + 96) is used to horizontally center the last frame inside the viewport
    transform: translateY(-50%) translateX(-98%);
  }
}

</style>
