<template functional>
  <svg :fill="props.fill" class="icon icon--codepen" :class="props.classes" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 512 512">
    <path d="M477.27 169.49L267.04 29.33c-7.18-4.42-14.84-4.46-22.08 0L34.73 169.49A20.1 20.1 0 0 0 26 185.9v140.16a20.1 20.1 0 0 0 8.73 16.42l210.23 140.18c7.18 4.42 14.84 4.46 22.08 0L477.27 342.5a20.09 20.09 0 0 0 8.73-16.42V185.9a20.1 20.1 0 0 0-8.73-16.42zm-201.5-86.77l154.78 103.2-69.05 46.2-85.73-57.25V82.72zm-39.54 0v92.15l-85.73 57.25-69.05-46.2 154.78-103.2zM65.53 222.88l49.54 33.11-49.54 33.11v-66.22zm170.7 206.38L81.45 326.06l69.05-46.2 85.73 57.25v92.15zM256 302.7l-69.82-46.72L256 209.27 325.82 256 256 302.7zm19.77 126.55V337.1l85.73-57.25 69.05 46.2-154.78 103.2zm170.7-140.16L396.93 256l49.54-33.11v66.22z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCodepen',
  props: {
    fill: {
      type: String,
      default: ''
    },
    classes: {
      type: String,
      default: ''
    }
  }
}
</script>
