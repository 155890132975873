<template>
  <div id="app">
    <AppHeader></AppHeader>

    <IntroAnimation v-if="introAnimationShown === false"></IntroAnimation>

    <main class="page">
      <transition name="router-anim">
        <router-view />
      </transition>
    </main>

    <AppFooter></AppFooter>
  </div>
</template>

<script>
// Layout
import AppHeader from './layout/AppHeader.vue'
import AppFooter from './layout/AppFooter.vue'

// Intro animation
import IntroAnimation from './components/intro-animation/IntroAnimation.vue'

// Keyboard focus
import keyboardFocus from 'keyboard-focus'

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    IntroAnimation
  },
  data: function () {
    return {
      introAnimationShown: true
    }
  },
  mounted () {
    // Handle keyboard navigating
    //
    keyboardFocus(document)

    // Based on cookie, show/hide intro animation
    //
    if (this.$cookie.get('introAnimationShown') === 'true') {
      this.introAnimationShown = true
    } else {
      this.introAnimationShown = false
    }

    // Check for webp support, allowing css webp functionality
    // with png fallback if no support
    //
    this.checkWebPSupport()
  },
  watch: {
    $route (to, from) {
      setTimeout(function () {
        window.scrollTo(0, 0)
      }, 1000)
    }
  },
  methods: {
    checkWebPSupport () {
      const html = document.documentElement
      const WebP = new Image()

      WebP.onload = WebP.onerror = function () {
        const isSupported = (WebP.height === 2)

        if (isSupported) {
          html.classList.add('webp')
        } else {
          html.classList.add('no-webp')
        }

        setTimeout(function () {
          if (html.classList.contains('webp')) {
            document.querySelector('.bg-layer').style.backgroundImage = 'url(/img/t/extinguisher.webp)'
          } else {
            document.querySelector('.bg-layer').style.backgroundImage = 'url(/img/t/extinguisher.png)'
          }
        }, 500)
      }
      WebP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA'
    }
  }
}
</script>

<style lang="scss">
// ==============================
// Import all scss from styles folder into the app
// ========================================

@import 'styles/main';

#app {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

// ==============================
// Router transition styles
// ========================================

.router-anim-enter-active {
  animation: coming .5s;
  animation-delay: 1s;
  opacity: 0;

  @media (prefers-reduced-motion) {
    animation: none;
    opacity: 1;
  }
}

.router-anim-leave-active {
  animation: going .01s;
  animation-delay: 0.5s;

  @media (prefers-reduced-motion) {
    animation: none;
    opacity: 0;
  }
}

@keyframes going {
  to {
    opacity: 0;
  }
}
@keyframes coming {
  to {
    opacity: 1;
  }
}
</style>
